export enum GettersType {
  GET_STATUS_TICKETS = "getStatusTickets",
  GET_PROJECTS = "getProjects",
  GET_PROJECT = "getProject",
  GET_TICKET_TYPES = "getTicketTypes",
  GET_DEPARTAMENTOS = "getDepartamentos",
  GET_TICKETS = "getTickets",
  GET_PRIORITIES = "getPriorities",
  GET_CURRENT_USER = "getCurrentUser",
  IS_ADMIN = "isAdmin",
  GET_FULLSCREEN = "getFullScreen",
  GET_LAST_RESULT = "getLastResult",
  IS_MUTE = "isMute",
  GET_STREAMING_LOADING = "getStreamingLoading",
  GET_STREAMING = "getStreaming",
  GET_STREAMING_ERROR = "getStreamingError",
  GET_STREAMING_RELOAD = "getStreamingReload",
  GET_STREAMING_PAUSE = "getStreamingPause",
  GET_GAME = "getGame",
  GET_GAMES = "getGames",
  GET_VOLUME = "getVolume",
  GET_PLAYER = "getPlayer",
  GET_CURRENCY = "getCurrency",
  CHIP_ACTIVE = "getActiveChip",
  GET_CHIPS = "getChips",
  GET_BET = "getBet",
  GET_ROUND = "getRound",
  GET_BET_TIME = "getBetTime",
  GET_BET_TIME_DURATION = "getBetTimeDuration",
  GET_CYCLE = "getCycle",
  GET_TOTAL_AMOUNT = "getTotalAmount",
  GET_MULTIPLIER = "getMultiplier",
  GET_LANGUAGE = "getLenguage",
  GET_LAST_BET = "getLastBet",
  GET_BALANCE = "getBalance",
  GET_AUTOMATIC_GAME = "getAutomaticGame",
  GET_AUTOMATIC_ROUND = "getAutomaticRound",
  GET_OPEN_AUTOMATIC_GAME = "getOpenAutomaticGAme",
  GET_OPERATOR = "getOperator",
  IS_WIN = "isWin",
  GET_EARNING = "getEARNING",
  SHOW_HISTORY_BET = "showHistoryBet",
  GET_INIT_BOND = "getInItBond",
  GET_CYCLONE_BOND = "getCycloneBond",
  GET_RAY_BOND = "getRaisedBond",
  GET_TOKEN = "getToken",
  GET_BACKGROUND_IMAGE = "getBackgroundImage",
  GET_SESSION_ID = "getSessionId",
  GET_MAX_BET_FIGURE = "getMaxBetFigure",
  GET_COUNT_BET_FIGURE = "getCountBetFigure",
  GET_TOTAL_AMOUNT_LAST_BET = "getTotalAmountLastBet",
  GET_BUTTON_LOBBY = "getButtonLobby",
  GET_BUTTON_SUPPORT = "getButtonSupport",
  GET_PROGRESS = "getProgress",
  GET_FIGURES = "getFigures",
  IS_VIDEO_ONLY = "getVideoOnly",
  IS_BOARD_ONLY = "getBoardOnly"
}