import { createStore } from "vuex";
import { IStoreType } from "@/interfaces/IStoreType";
import UserMutation from "@/store/mutations/user";
import UserGetters from "@/store/getters/user";
import GameAction from "@/store/actions/game";
import PlayerGetters from "@/store/getters/player";
import PlayerMutations from "@/store/mutations/player";
import GameGetters from "@/store/getters/game";
import GameMutations from "@/store/mutations/game";
import ChipMutations from "@/store/mutations/chip";
import ChipGetters from "@/store/getters/chip";
import ChipActions from "@/store/actions/chip";
import OperatorGetters from "@/store/getters/oerator";
import OperatorMutation from "@/store/mutations/operator";

export default createStore<IStoreType>({
  state: {
    fullScreen: false,
    token: "",
    user: "",
    lastResult: null,
    mute: true,
    settings: false,
    videoQuality: "auto",
    videoStreaming: true,
    videoStreamingPause: "",
    videoStreamingError: false,
    videoStreamingLoading: false,
    videoStreamingReload: false,
    videoColor: 'black',
    game: null,
    volume: 0,
    player: null,
    currency: null,
    chipListOpen: false,
    chips: [],
    bet: null,
    lastBet: [],
    round: null,
    cycle: 2,
    cycleText: "",
    isWin: false,
    multipliers: [],
    lang: "",
    automaticGameOpen: false,
    automaticGame: false,
    automaticRounds: 10,
    automaticGametValue: 0,
    operator: null,
    earning: null,
    showHistoryBet: false,
    lobbyHost:"",
    lobby:"",
    megaBond: {
      init:false,
      cyclone:false,
      ray:false,
    },
    imgBackground: null,
    sessionID:null,
    maxBetFigures:null,
    countBetFigures:0,
    button_lobby: false,
    button_support: true,
    contactUs:true,
    progress:10,
    isVideoOnly:false,
    isBoardOnly: false




  },
  getters: {
    ...UserGetters,
    ...PlayerGetters,
    ...GameGetters,
    ...ChipGetters,
    ...OperatorGetters,
  },
  mutations: {
    ...UserMutation,
    ...GameMutations,
    ...PlayerMutations,
    ...ChipMutations,
    ...OperatorMutation,
  },
  actions: {
    ...GameAction,
    ...ChipActions,
  },
  modules: {},
});
