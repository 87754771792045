import { MutationType } from "@/store/mutations/type";
import { IStoreType } from "@/interfaces/IStoreType";
import { IGame } from "@/interfaces/IGame";
import { IBet } from "@/interfaces/IBet";
import { IRound } from "@/interfaces/IRound";
import { IMultiplier } from "@/interfaces/IMultiplier";
import { ISimpleBet } from "@/interfaces/ISimpleBet";

export default {
  [MutationType.SET_GAME]: (state: IStoreType, val: IGame): void => {
    state.game = val;
  },
  [MutationType.SET_BET]: (state: IStoreType, val: IBet): void => {
    state.bet = val;
  },
  [MutationType.SET_ROUND]: (state: IStoreType, val: IRound): void => {
    state.round = val;
  },
  [MutationType.SET_CYCLE]: (state: IStoreType, val: number): void => {
    state.cycle = val;
  },
  [MutationType.SET_CYCLE_TEXT]: (state: IStoreType, val: string): void => {
    state.cycleText = val;
  },
  [MutationType.RESET_SIMPLE_BET]: (state: IStoreType): void => {
    if (state.bet) state.bet.bet = [];
  },
  [MutationType.SET_BET_TIME]: (state: IStoreType, val: number): void => {
    if (state.round) state.round.betTime = val;
  },
  [MutationType.SET_MULTIPLIERS]: (
    state: IStoreType,
    val: IMultiplier[]
  ): void => {
    state.multipliers = val;
  },
  [MutationType.SET_LANGUAGE]: (state: IStoreType, val: string): void => {
    state.lang = val;
  },
  [MutationType.SET_LAST_BET]: (state: IStoreType, val: ISimpleBet[]): void => {
    state.lastBet = val;
  },
  [MutationType.SET_AUTOMATIC_GAME]: (
    state: IStoreType,
    val: boolean
  ): void => {
    state.automaticGame = val;
  },
  [MutationType.OPEN_AUTOMATIC_GAME]: (state: IStoreType): void => {
    state.automaticGameOpen = !state.automaticGameOpen;
  },
  [MutationType.ADD_AUTOMATIC_BET]: (state: IStoreType): void => {
    if (!state.bet) return;
    state.bet.bet = state.lastBet;

  },
  [MutationType.SET_AUTOMATIC_ROUND]: (
    state: IStoreType,
    val: number
  ): void => {
    state.automaticRounds = val;
  },
  [MutationType.SET_AUTOMATIC_VALUE]: (
    state: IStoreType,
    val: number
  ): void => {
    state.automaticGametValue = val;
  },
  [MutationType.RESET_AUTOMATIC_BET]: (state: IStoreType): void => {
    state.automaticGameOpen = false;
    state.automaticGame = false;
    state.automaticRounds = 10;
    state.automaticGametValue = 0;
  },
  [MutationType.TOGGLE_SETTING]:(state: IStoreType): void => {
    state.settings = !state.settings
  },
  [MutationType.SET_WIN]: (state: IStoreType, val: boolean): void => {
    state.isWin = val;
  },
  [MutationType.SET_EARNINGS]: (state: IStoreType, val: number): void => {
    state.earning = val;
  },
  [MutationType.SET_SHOW_HISTORY_BET]: (state: IStoreType): void => {
    state.showHistoryBet = !state.showHistoryBet;
  },
  [MutationType.SET_INIT_BOND]:(state: IStoreType, val: boolean):void => {
    state.megaBond.init = val;
  },
  [MutationType.SET_CYCLONE_BOND]:(state: IStoreType, val: boolean): void => {
    state.megaBond.cyclone = val;
  },
  [MutationType.SET_RAY_BOND]:(state: IStoreType, val:boolean): void => {
    state.megaBond.ray = val;
  },
  [MutationType.SET_LOBBY_HOST]:(state: IStoreType, val:string): void => {
    state.lobbyHost = val;
  },
  [MutationType.SET_LOBBY]:(state: IStoreType, val:string): void => {
    state.lobby = val;
  },
  [MutationType.SET_MUTE]:(state: IStoreType, val:boolean): void => {
    state.mute = val;
  },
  [MutationType.SET_BACKGROUND_IMAGE]:(state: IStoreType, val:string): void => {
    state.imgBackground = val;
  },
  [MutationType.SET_VIDEO_COLOR]:(state: IStoreType,color:string): string =>state.videoColor = color,
  [MutationType.SET_MAX_BET_FIGURE]:(state: IStoreType,max:number|null): number | null => state.maxBetFigures = max,
  [MutationType.SET_COUNT_BET_FIGURE]:(state: IStoreType,max:number): number => state.countBetFigures = max,
  [MutationType.SET_BUTTON_LOBBY]:(state: IStoreType,btn:boolean): boolean => state.button_lobby = btn,
  [MutationType.SET_BUTTON_SUPPORT]:(state: IStoreType,btn:boolean): boolean => state.button_support = btn,
  [MutationType.SET_CONTACTUS]:(state: IStoreType,btn:boolean): boolean => state.contactUs = btn,
  [MutationType.SET_PROGRESS]:(state: IStoreType,btn:number): number => state.progress = btn,
  [MutationType.SET_VIDEO_ONLY]:(state: IStoreType,val:boolean): boolean => state.isVideoOnly = val,
  [MutationType.SET_BOARD_ONLY]:(state: IStoreType,val:boolean): boolean => state.isBoardOnly = val,
};
